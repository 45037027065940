import React, { useEffect } from 'react';
import Prism from "prismjs";
import SgLayout from "~layouts/sg-layout";
import CodeBlock from '~2-components/SgCodeBlock/SgCodeBlock';
import AllNewsArticleList from '~/2-components/AllNewsArticleList/AllNewsArticleList';
import { getAllNewsArticles } from '~3-data-components/AllNewsArticleKontent';
import { allNewsArticleData } from '~/data/allNewsArticleData';

const allNewsArticleListEg = `import AllNewsArticleList from '~2-components/ArticleAuthor/AllNewsArticleList';

<AllNewsArticleList
    articles=array // Required
/>
`;

const SgAllNewsArticle = () => {
    useEffect(() => {
        // call the highlightAll() function to style our code blocks
        Prism.highlightAll();
    });

    return (
        <SgLayout>
            <section className="sg-content">
                <div className="container">
                    <h1>All News Article</h1>
                    <h3>How to use</h3>
                    <CodeBlock
                        code={allNewsArticleListEg}
                    />

                    <h3>Example with dummy data</h3>
                </div>
            </section>
            <section className="project-example">
                <div className="container">
                    <AllNewsArticleList
                        articles={allNewsArticleData}
                    />
                </div>
            </section>
            <section className="sg-content">
                <div className="container">
                    <h3>Example with live</h3>
                </div>
            </section>
            <section className="project-example">
                <div className="container">
                    <AllNewsArticleList
                        articles={getAllNewsArticles()}
                    />
                </div>
            </section>
        </SgLayout>
    );
};

export default SgAllNewsArticle;
